import React, { useEffect } from 'react';
import image from '../img/Kitchen2.jpeg'
import '../Css/Kitchen.css'
import 'aos/dist/aos.css';
import AOS from 'aos';

export default function Kitchen() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        
        <>
            <div data-aos="fade-right" className="kitchen">
                <div className="kitchen-content">
                    <div className="kitchen-images">
                        <img src={image} alt="Image1" className="kitchen-image" />
                    </div>
                    <div className="kitchen-text">
                        <h1>Kitchen Work</h1>
                        <p>
                            Your cafe's soul is its kitchen gear. Any failing apparatus could bring disaster.
                            Keeping up with your hardware appropriately is one of the most amazing strategies to keep away from this from occurring.
                            Occasional support not just sets aside you cash in the long haul, however it additionally guarantees that your guarantee is substantial and that your kitchen hardware is working at top proficiency.
                            Inappropriate establishment and absence of upkeep are the two most regular explanations behind guarantees being void.
                            Konnecta administrations can help you with Kitchen Support Administrations and Kitchen Remodel Administrations.
                            The significant brands of business kitchen gear are adjusted by Konnecta specialized help. Which is industrial facility approved. Our experts are producer prepared in apparatus and refrigeration upkeep.
                            We additionally give wall painting administrations in Dubai.
                        </p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" className='kitchen-text2 kitchen-text'>
                <h1>Kitchen Maintenance Services</h1>
                <p>
                    Konnecta technical services is satisfied to offer one of the business'
                    most thorough preventive support plans. At the point when you reach us for routine support,
                    you can have confidence that your kitchen hardware will be investigated and really focused on consistently.
                    It's not difficult to become overpowered by the possibility of running a kitchen, particularly on the off chance that your machines quit working.
                    From broken dishwashers to warmed fridges, we offer the information and experience you want. Keeping up with the proficiency of your kitchen is basic to the outcome of any catering business.
                    Assuming that your dishwashing machine breaks, your representatives will invest more energy cleaning tableware and flatware and less time on different errands.
                </p>
            </div>
        </>
    )
}
