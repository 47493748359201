import React, { useState ,useEffect} from 'react';
import image from '../img/contactus.jpg'; // Adjust the path as necessary
import footer from '../img/footer.jpeg';
import '../Css/HomeContact.css'
import 'aos/dist/aos.css';
import AOS from 'aos';

export default function HomeContact() {
    useEffect(() => {
        AOS.init({ duration: 3000 });
    }, []);

    const [result, setResult] = useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "f27aaaf2-b793-41bc-b556-c19941febea7");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    return (
        <>
            <div data-aos="fade-up"
     data-aos-anchor-placement="top-center" className="container my-5">
                <div className="row">
                    <div className="col-lg-6 col-md-12 text-center">
                        <img src={image} className="img-fluid" alt="Contact Us" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="text-left mb-4">
                            <h6 className="text-uppercase text-success">Contact Us</h6>
                            <h1 className="font-weight-bold">You Can Contact Us,</h1>
                            <h2>If You Have Any Query</h2>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name="name"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <div className="input-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter your email"
                                            name="email"
                                            required
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fa fa-envelope"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone No (Optional)"
                                        name="phone"
                                    />
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Contact Subject"
                                        name="subject"
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <textarea
                                    className="form-control"
                                    rows="4"
                                    placeholder="Comments"
                                    name="comments"
                                ></textarea>
                            </div>
                            <div className="text-left">
                                <button type="submit" className="btn btn-secondary btn-lg">Submit</button>
                            </div>
                        </form>
                        <span>{result}</span>
                    </div>
                </div>
            </div>

            <div>
                <img src={footer} alt="img" className='img-fluid footer_img' />
            </div>
        </>
    );
}
