import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../Css/Wall_painting.css'
import image from '../img/paint2.jpeg'

export default function WallPainting() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
}, []);
  return (
    <>
      <div data-aos="fade-right" className="wall_painting">
        <div className="wall_painting-content">
          <div className="wall_painting-images">
            <img src={image} alt="Image1" className="wall_painting-image" />
          </div>
          <div className="wall_painting-text">
            <h1>Wall Painting</h1>
            <p>
              A tip to show an outside show of a structure, house, or working environment is to utilize Wall Painting Administration. We give master wall painting administrations in Dubai, and Konnecta technical services is one of the top wall painting organization in Dubai. Konnecta  services gives painting administrations all through the UAE, as a matter of fact.
              At whatever point and as a matter of some importance, while choosing painting administrations for your home, you ought to think about your choices prior to settling on a firm.
            </p>
            <p>
              We offer proficient work of art administrations in Dubai, UAE, including home canvas, inside painting, loft painting, office painting, and wall painting. Before we begin painting, we as a whole need to perceive how the completed item will look. Painting your house is exciting, however settling on the right tones, supplies, and house painters while shielding the wellbeing of your family can be a troublesome test. That is where we come in, determined to make painting at home a protected and pleasant experience! Rethink your home as an enormous material with Konnecta technical services Painting Administrations.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-right" className='wall_painting-text2 wall_painting-text'>
        <h1>Extraordinary Wall Painting Services in Dubai</h1>
        <p>We provide complete visualization assistance with infinite colours so you can get started on your project right away. And your home will receive the utmost treatment and care with our award-winning house painting service.</p>
        <ul>
          <li>Consider all the colours that are available.</li>
          <li>Try out all the colours in your own home.</li>
          <li>Colour exploration in a new light.</li>
        </ul>
      </div>
    </>
  )
}
