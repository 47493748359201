import React from 'react';
import '../Css/Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'; // Add faBars and faTimes for hamburger and close icons
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import img2 from '../img/gallery2.jpeg'
import img3 from '../img/gallery3.jpeg'
import img4 from '../img/gallery4.jpeg'
import img5 from '../img/galley5.jpeg'

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-section">
        <h1>WHO WE ARE</h1>
        <p>Konnecta, believe in quality, innovation, and precision which is depicted from our work as well. Our skilled professionals are committed and passionate to deliver the best results to our customers.</p>
        <div className='contact-icon'>
          <a className='text-white' href='tel:+971509318826'>
            <FontAwesomeIcon className='phone_icon' shake icon={faPhone} style={{ color: "green" }} /> +971509318826
          </a>
          <a style={{marginTop:"5px"}} className='text-white' href='mailto:Info@konnectatechnicalService.com'>
            <FontAwesomeIcon icon={faEnvelope} bounce style={{ color: "darkblue" }} /> Info@konnectatechnicalservice.com
          </a>
        </div>
        <div className='social-icons' style={{marginTop:"5px"}}>
          <a style={{marginRight:"25px"}} href="https://www.facebook.com/profile.php?id=61565176625049" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='soc-button social' size="lg" icon={faFacebookF} style={{ color: "darkblue" }} />
          </a>
          <a href="https://www.instagram.com/konnecta.0" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='soc-button social' size="lg" icon={faInstagram} style={{ color: "hotpink" }} />
          </a>
        </div>
      </div>
      <div className="footer-section">
        <h1>QUICK LINKS</h1>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/aboutus">About Us</Link></li>
          <li><Link to="/service">Our Services</Link></li>
          <li><Link to="/gallery">Gallery</Link></li>
          <li><Link to="/contactus">Contact Us</Link></li>
        </ul>
      </div>
      <div className="footer-section">
        <h1>OUR SERVICES</h1>
        <ul>
          <li><Link to="/service/tileMarble">Tile Marble Fixing Company</Link></li>
          <li><Link to="/service/kitchen">Kitchen Renovation</Link></li>
          <li><Link to="/service/ac">Electrical and Air Conditiong</Link></li>
          <li><Link to="/service/falsecelling">False Ceiling Works</Link></li>
          <li><Link to="/service/wallpainting">Wall Painting Service</Link></li>
          <li><Link to="/service/gardenmaintainance">Garden Maintainance</Link></li>
          <li><Link to="/service/plumbing">Plumbing & Sanitary Contracting</Link></li>
          <li><Link to="/service/carpentry">Carpentry Contracting</Link></li>
          <li><Link to="/service/swimmingpool">Swimming Pools</Link></li>
        </ul>
      </div>
      <div className="footer-section">
        <h1>GALLERY</h1>
        <div className="gallery-images">
          {/* Add your images here */}
          <img src={img2} alt="Image1" />
          <img src={img3} alt="Image2" />
          <img src={img4} alt="Image3" />
          <img src={img5} alt="Image4" />
        </div>
      </div>
      <p className="footer-copyright">&copy; 2024 Konnecta Technical Services. All rights reserved.</p>
    </div>
  );
}
