import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import image from '../img/swimming2.jpeg'
import '../Css/SwimmingPool.css'

export default function SwimmingPool() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <>
            <div data-aos="fade-right" className="swimmingpool">
                <div className="swimmingpool-content">
                    <div className="swimmingpool-images">
                        <img src={image} alt="Image1" className="swimmingpool-image" />
                    </div>
                    <div className="swimmingpool-text">
                        <h1>Swimming Pool Maintenance & Swimming Pool Tile Fixing</h1>
                        <p>
                            Utilizing the administrations of a legitimate pool support Dubai guarantees that your pool upkeep issues will be settled rapidly and effectively. This is on the grounds that main a very much kept up with pool " one that has been overhauled and fixed consistently " will look alluring, guard your family from contamination, and shield the general climate from harm. For this reason it's vital to have a pool support administration in your group
                        </p>
                        <p>
                            Using the services of a reputable swimming pool maintenance Dubai ensures that your pool maintenance problems will be resolved quickly and efficiently. This is because only a well-maintained pool â€“ one that has been serviced and repaired on a regular basis â€“ will look attractive, keep your family safe from infection, and protect the surrounding environment from damage. This is why it’s crucial to have a pool maintenance service on your team.
                        </p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" className='swimmingpool-text2 swimmingpool-text'>
                <h1>Why choose Konnecta technical services for Swimming Pool Maintenance & Tile Fixing</h1>
                <p>
                    We get it, Konnecta technical services, that you need to have the option to utilize your pool at whatever point you need without agonizing over the temperature, ph levels, lucidity, or different elements. Permitting our experts to deal with your pool's renovation that it is in ideal working request consistently and prepared for you to utilize. Going from pool tile fixing to cleaning, Konnecta technical services complete a wide range of expert and committed support. We have some expertise in cleaning and cleanly keeping up with pools. We furnish our clients with pool cleaners who are prepared, qualified, and experienced, as well as the most cutting-edge gear. We can assist clients with month to month pool cleaning administrations as well as one-time extreme or profound cleanings. Furthermore, we give and introduce pool covers, pool cover reels, stepping stools, water testing units, pool synthetic substances, and, surprisingly, programmed cleaners.
                </p>
            </div>
        </>
    )
}
