import React, { useContext } from 'react';
import SearchContext from './SearchContext';
import contentData from './data';

const SearchResults = () => {
  const { query } = useContext(SearchContext);
  const results = contentData.filter(item =>
    item.title.toLowerCase().includes(query.toLowerCase()) ||
    item.content.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div>
      <h2>Search Results</h2>
      {results.length > 0 ? (
        results.map((result, index) => (
          <div key={index}>
            <h3>{result.title}</h3>
            <p>{result.content}</p>
          </div>
        ))
      ) : (
        <p>No results found for "{query}".</p>
      )}
    </div>
  );
};

export default SearchResults;
