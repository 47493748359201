import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Service from './components/Services';
import Gallery from './components/Gallery';
import TileMarble from './components/TileMarble';
import Ac from './components/Ac';
import WallPainting from './components/WallPainting';
import Kitchen from './components/Kitchen';
import SwimmingPool from './components/SwimmingPool';
import FalseCelling from './components/FalseCelling';
import Gardenmaintain from './components/Gardenmaintain';
import Plumbing from './components/Plumbing';
import Carpentry from './components/Carpentry';
import Contactus from './components/Contactus';
import SearchResults from './components/SearchResults';
import ScrollToTop from './components/ScrollToTop';
import { SearchProvider } from './components/SearchContext';

function App() {
  return (
    <div className="App">
      <SearchProvider>
        <Router>
          <Navbar title="Konnecta Technical Service" />
          <ScrollToTop /> {/* This component ensures scrolling to the top */}
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/aboutus' element={<AboutUs />} />
            <Route path='/service' element={<Service />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/service/tileMarble' element={<TileMarble />} />
            <Route path='/service/ac' element={<Ac />} />
            <Route path='/service/wallpainting' element={<WallPainting />} />
            <Route path='/service/kitchen' element={<Kitchen />} />
            <Route path='/service/swimmingpool' element={<SwimmingPool />} />
            <Route path='/service/falsecelling' element={<FalseCelling />} />
            <Route path='/service/gardenmaintainance' element={<Gardenmaintain />} />
            <Route path='/service/plumbing' element={<Plumbing />} />
            <Route path='/service/carpentry' element={<Carpentry />} />
            <Route path='/contactus' element={<Contactus />} />
            <Route path='/search' element={<SearchResults />} />
          </Routes>
          <Footer />
        </Router>
      </SearchProvider>
    </div>
  );
}

export default App;
