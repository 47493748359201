import React, { useEffect } from 'react';
import image from '../img/Ac2.jpeg'
import '../Css/AC.css'
import 'aos/dist/aos.css';
import AOS from 'aos';

export default function Ac() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
}, []);
  return (
    <>
      <div data-aos="fade-right" className="ac">
        <div className="ac-content">
          <div className="ac-images">
            <img src={image} alt="Image1" className="ac-image" />
          </div>
          <div className="ac-text">
            <h1>Electrical & AC mainatinanace</h1>
            <p>
              Hoping to keep your cooling framework spotless, dependable, and successful so you can live in a solid and agreeable climate? You've found the top ac Upkeep Organization in Dubai, Joined Middle Easterner Emirates.
              Konnecta technical services has been doing business for quite a while and has met the different necessities of our clients, each with their own arrangement of prerequisites and determinations.
              Our administrations are exceptionally pursued in the market by various clients, attributable to the excellent we can convey and the client-driven approach we take with each task.
            </p>
            <p>
              We have a group of talented cooling specialists and experts who know how to finish any undertaking productively and successfully. Beside guaranteeing perfect and empowering air, the cleaned framework will run all the more effectively, bringing down costs fundamentally.
              But Ac Upkeep Organization in Dubai Konnecta technical services benefits.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-right" className='ac-text2 ac-text'>
        <h1>Importance of A/C Maintenance</h1>
        <p>Keeping a spotless, sound, and open to living climate requires having a very much kept up with cooling framework. Besides, an appropriately cleaned air channel framework is more proficient than a filthy framework, bringing about less expensive home and medical care administration consumptions. There is no question that the framework's aviation route will become home to various bugs, microorganisms, and organisms that will corrupt air quality. For certain, ordinary consideration will kill a wide range of microbes and microorganisms. The Air conditioner framework is cleaned of residue, grime, dregs, creature dung, and different pollutants. This aviation route framework establishes a rearing climate for microbes, parasite, buildup, and different organic entities, which are then reused all through your home, hurting your family's wellbeing and solace. We will guarantee that your cooling framework will continuously work at its best by consolidating these cycles and tasks.</p>
      </div>
    </>
  )
}
