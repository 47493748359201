import React, { useEffect } from 'react';
import image from '../img/Carpenter2.jpeg'
import '../Css/Carpenter.css'
import 'aos/dist/aos.css';
import AOS from 'aos';

export default function Carpentry() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <>
            <div data-aos="fade-right" className="carpenter">
                <div className="carpenter-content">
                    <div className="carpenter-images">
                        <img src={image} alt="Image1" className="carpenter-image" />
                    </div>
                    <div className="carpenter-text">
                        <h1>Carpentry & Contracting</h1>
                        <p>
                        Konnecta Technical Services is a division where craftsmanship meets creativity. Our skilled carpenters are dedicated to providing bespoke solutions tailored to your specific needs, transforming your ideas into tangible results that enhance both the functionality and aesthetics of your space.
                        </p>
                        <p>
                        Our carpenters specialize in crafting custom furniture pieces that fit seamlessly into your home or business environment. Whether you envision a sleek modern design or a rustic, traditional look, we collaborate closely with you to create furniture that complements your style and fulfills your practical requirements.
                        </p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" className='carpenter-text2 carpenter-text'>
                <h1>Why Choose Us?</h1>
                <p>
                    We offer comprehensive woodwork services, including repairs and restorations. From fixing broken furniture and cabinetry to refurbishing vintage pieces, our carpenters bring new life to your beloved wooden items. Our attention to detail ensures that every repair is executed with precision and care.
                    Whether you’re looking to enhance your living space or create a more functional work environment, our carpenter services are designed to meet your unique needs. Contact us today to discuss your project and discover how we can bring your vision to life.
                </p>
            </div>
        </>
    )
}
