import React from 'react';
import '../Css/Home_middle.css';
import people from '../img/people.png';
import people2 from '../img/people2.png';
import house from '../img/house.png';
import work from '../img/works.png';

export default function Home_middle() {
    
    return (
        <div   className='parent_row'>
            <div className='sub1_row'>
                <div className='sub_row'>
                    <img src={house} alt="House" />
                    <h3 className='title'>01. Quality Materials</h3>
                    <p className='para'>Any services and the executives we utilize the best quality materials for the best result and client satisfaction.</p>
                </div>
                <div className='sub_row'>
                    <img src={people2} alt="House" />
                    <h3 className='title'>02. Expert Team</h3>
                    <p className='para'>From the ground staff to the field group we have the own field ability individuals, who carries sorcery and stylish to the work.</p>
                </div>
                <div className='sub_row'>
                    <img src={people} alt="House" />
                    <h3 className='title'>03. Professionalism</h3>
                    <p className='para'>We esteem the quality and keep up with the normalization in our work casing and make the expert touch in its level.</p>
                </div>
                <div className='sub_row'>
                    <img src={work} alt="House" />
                    <h3 className='title'>04. Safe & Secure</h3>
                    <p className='para'>Associate us and feel ease, the works and its quality is no problem at all in our grasp. We face the challenge and carry the best with a got way.</p>
                </div>
            </div>
        </div>
    )
}
