import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import image from '../img/tile2.jpeg'
import '../Css/TileMarble.css'

export default function TileMarble() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
}, []);
  return (
    <>
    <div data-aos="fade-right" className="tile-marble">
      <div className="tile-marble-content">
        <div className="tile-marble-images">
          <img src={image} alt="Image1" className="tile-marble-image"  />
        </div>
        <div className="tile-marble-text">
          <h1>Marble Fixing</h1>
          <p>
          We are expert providers fixers and restorers of marble, rock, record, limestone, quartz, york stone and so on for a wide range of deck including tracks, risers
          </p>
          <p>
          We can supply efficiently manufactured tiles or we can cut and shape tiles from piece to give you that custom tailored task to accommodate your sizes and necessities remembering that custom tailored from chunk is more costly. Floors can be to an example and be regardless of boundaries. On the off chance that you need supply just that isn't an issue or we can do your cutting, molding in our processing plant at additional expense assuming you require this help.
          </p>
        </div>
      </div>
    </div>
    </>
  )
}
