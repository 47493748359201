import React, { useEffect } from 'react';
import marbel from '../img/marble.jpeg';
import pool from '../img/swimming.jpeg';
import paint from '../img/painting.jpeg';
import Kitchen from '../img/kitchen.jpeg';
import garden from '../img/Gardening.jpeg';
import electrical from '../img/electrical.jpeg';
import plumbing from '../img/plumbing.jpeg';
import ceiling from '../img/celling.jpeg';
import Carpentry from '../img/carpenter.jpeg';
import { Link} from 'react-router-dom';
import '../Css/Homeservice.css'; // Import the custom CSS
import 'aos/dist/aos.css';
import AOS from 'aos';

export default function HomeServices() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <>
        <div data-aos="fade-up"
     data-aos-anchor-placement="top-center" className='container my-5'>
            <h1 className='text-center'>Services</h1>
            <hr/>
            <div  className='row'>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={marbel} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Tile Marble</h5>
                            <p className="card-text">Darlak Fixing, Marble Fixing, Tile Fixing.</p>
                            <Link to="/service/tileMarble" className="btn btn-secondary ">Read More</Link>
                        </div>
                    </div>
                </div>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={electrical} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Electrical & Air Conditioning</h5>
                            <p className="card-text">All electrical work , maintenance work.</p>
                            <Link to="/service/ac" className="btn btn-secondary">Read More</Link>
                        </div>
                    </div>
                </div>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={ceiling} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">False Ceiling</h5>
                            <p className="card-text">All false ceiling work</p>
                            <Link to="/service/falsecelling" className="btn btn-secondary">Read More</Link>
                        </div>
                    </div>
                </div>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={paint} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Wall Painting</h5>
                            <p className="card-text">Diko,Sitko,Phutti & all Water Paints.</p>
                            <Link to="/service/wallpainting" className="btn btn-secondary">Read More</Link>
                        </div>
                    </div>
                </div>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={pool} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Swimming Pools</h5>
                            <p className="card-text">All swimming pool work, tile fixing.</p>
                            <Link to="/service/swimmingpool" className="btn btn-secondary">Read More</Link>
                        </div>
                    </div>
                </div>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={plumbing} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Plumbing & Sanitary</h5>
                            <p className="card-text">All plumbing and sanitary work.</p>
                            <Link to="/service/plumbing" className="btn btn-secondary">Read More</Link>
                        </div>
                    </div>
                </div>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={Carpentry} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Carpentry & Floor</h5>
                            <p className="card-text">Wood work and All Carpentry and Floor work.</p>
                            <Link to="/service/carpentry" className="btn btn-secondary">Read More</Link>
                        </div>
                    </div>
                </div>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={Kitchen} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Kitchen Renovation</h5>
                            <p className="card-text">Binko Paints, Polish, Kitchen Tile fixing.</p>
                            <Link to="/service/kitchen" className="btn btn-secondary">Read More</Link>
                        </div>
                    </div>
                </div>
                <div  className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                    <div className="card custom-card shadow-sm">
                        <img src={garden} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Garden Maintainance</h5>
                            <p className="card-text">All Garden Work, Garden Tile fixing.</p>
                            <Link to="/service/gardenmaintainance" className="btn btn-secondary">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}