import React, { useEffect } from 'react';
import image from '../img/aboutus.jpeg' 
import '../Css/Aboutus.css'
import 'aos/dist/aos.css';
import AOS from 'aos';

export default function AboutUs() {
  
  useEffect(() => {
    AOS.init({ duration: 2000 });
}, []);
  return (
    
    <>
    <div className='aboutus'>
      <h1>AboutUs</h1>
    </div>
    <div className="about-us " data-aos="fade-right">
      <div className="about-us-content">
        <div className="about-us-images">
          <img src={image} alt="Image1" className="about-us-image"  />
        </div>
        <div  className="about-us-text">
          <h1>About Us</h1>
          <p>
          At Konnecta technical services we put stock in bringing and giving best to our clients at every one of our administrations.
          From garden when the best administrations are given its is and agreeable for the client, it can change your places into the best and more secure spaces, and there Konnecta technical service specialized administrations comes to give you an outline in complete arrangement giving
          </p>
          <p>
          Konnecta technical service, put stock in quality, advancement, and accuracy which is portrayed from our work too. Our gifted experts are committed and enthusiastic to convey the best outcomes to our clients. Your fulfillment makes the biggest difference in our business, consequently, we follow a cooperative and consultative methodology wherein we esteem your ideas and mix it with our development to draw out the best of our administrations.
          </p>
          <h2>Some of our areas of expertise are:</h2>
          <ul>
            <li><span>✔</span> Landscaping</li>
            <li><span>✔</span> Kitchen Works</li>
            <li><span>✔</span> Ac Maintenance</li>
            <li><span>✔</span> Technical Services</li>
            <li><span>✔</span> Painting Works</li>
            <li><span>✔</span> Swimming Pool Maintenance</li>
            <li><span>✔</span> False Ceiling</li>
            <li><span>✔</span> Plumber And Sanitory</li>
          </ul>
        </div>
      </div>
    </div>
    </>
  );
}
