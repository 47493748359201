import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import '../Css/Home_middle2.css';

export default function Home_middle2() {
    const whatsappLink = `https://wa.me/971509318826?text=Hello%20I%20am%20interested%20in%20your%20services`;


    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className='home_middle2'>
            <div className='subdiv_middle2'>
                <div  className='image'></div>
                <div className='content' data-aos="fade-right">
                    <div className='content_subdiv'>
                        <h1 >LEADING TECHNICAL SERVICES DUBAI</h1>
                        <p >
                            Konnecta Technical Services give a large number of Specialized Administrations in Dubai including wall painting, Tile Marble Fixing, Kitchen Upkeep, Pool Support and others with ease. We frequently attempt to our clients to offer better types of assistance at less problem with our specialized administrations in Dubai, whether they have private companies or large with numerous areas. <br /><br /> At Konnecta Technical Services we have confidence in bringing and giving best to our clients at every one of our administrations. From garden When the best administrations are given its is and agreeable for the client, it can change your places into the best and more secure spaces.
                        </p>
                        <ul >
                            <li>100% Satisfaction Guaranteed</li>
                            <li>Highly Skilled Technicians</li>
                            <li>Fast & Reliable Services</li>
                        </ul>
                        <p  className='green_text'>Konnecta, believe in quality, innovation, and precision which is depicted from our work as well. Our skilled professionals are committed and passionate to deliver the best results</p>
                        <Link to='/aboutus'>READ MORE</Link>
                    </div>
                </div>
            </div>
            <a  href={whatsappLink} className='floating-whatsapp' target='_blank' rel='noopener noreferrer'>
                <FontAwesomeIcon icon={faWhatsapp} size="2xl" style={{ color: "#009919" }} />
            </a>
        </div>
    );
}
