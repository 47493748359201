import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchContext from './SearchContext';
import contentData from './data';
import logo from '../img/logo.png';
import '../Css/Navbar.css';

export default function Navbar(props) {
  const { setQuery } = useContext(SearchContext);
  const [input, setInput] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(input);

    // Find the first matching content
    const result = contentData.find(item =>
      item.title.toLowerCase().includes(input.toLowerCase()) ||
      item.content.toLowerCase().includes(input.toLowerCase())
    );

    // Navigate to the page if a match is found
    if (result) {
      navigate(result.path);
    } else {
      navigate('/search'); // Fallback to a search results page or handle no results found
    }
  };


  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link className="navbar-brand fw-normal font-size-16 fw-bold" to="/"> 
            {props.title} <img className='logo' src={logo} alt="logo" /> 
            <div className='slogan'>SOLUTION TO YOUR DOOR STEP</div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item ">
                <Link className="nav-link" activeclassname="active" to="/">Home</Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" activeclassname="active" to="/aboutus">About Us</Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  activeclassname="active"
                  to="/service"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Our Service
                </Link>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="/service">Our Services</Link></li>
                  <li><hr className="dropdown-divider"/></li>
                  <li><Link className="dropdown-item" to="/service/tileMarble">Tile Marble Fixing Company</Link></li>
                  <li><Link className="dropdown-item" to="/service/kitchen">Kitchen Renovation</Link></li>
                  <li><Link className="dropdown-item" to="/service/ac">Electrical and Air Conditioning</Link></li>
                  <li><Link className="dropdown-item" to="/service/falsecelling">False Ceiling Works</Link></li>
                  <li><Link className="dropdown-item" to="/service/wallpainting">Wall Painting Service</Link></li>
                  <li><Link className="dropdown-item" to="/service/gardenmaintainance">Garden Maintenance</Link></li>
                  <li><Link className="dropdown-item" to="/service/plumbing">Plumbing & Sanitary Contracting</Link></li>
                  <li><Link className="dropdown-item" to="/service/carpentry">Carpentry Contracting</Link></li>
                  <li><Link className="dropdown-item" to="/service/swimmingpool">Swimming Pools</Link></li>
                </ul>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" activeclassname="active" to="/gallery">Gallery</Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link" activeclassname="active" to="/contactus">Contact Us</Link>
              </li>
            </ul>
            <form className="d-flex" role="search" onSubmit={handleSearch}>
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
              <button className="btn btn-outline-light" type="submit">Search</button>
            </form>
          </div>
        </div>
      </nav>
      </>
  );
}
