import React from 'react'
import img1 from '../img/Gallery1.jpeg'
import img2 from '../img/gallery2.jpeg'
import img3 from '../img/gallery3.jpeg'
import img4 from '../img/gallery4.jpeg'
import img5 from '../img/galley5.jpeg'

export default function Gallery() {
    return (
        <div>
            <div className='container my-5'>
                <h1 className='text-center'>Gallery</h1>
                <hr />
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                        <img src={img1} className="img-fluid" alt="..." />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                        <img src={img2} className="img-fluid" alt="..." />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                        <img src={img3} className="card-img-top" alt="..." />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                        <img src={img4} className="card-img-top" alt="..." />
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center mb-4'>
                        <img src={img5} className="card-img-top" alt="..." />
                    </div>
                </div>
            </div>
        </div>
    )
}
