import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import image from '../img/falsecelling2.jpeg'
import '../Css/FalseCelling.css'

export default function FalseCelling() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <>
            <div data-aos="fade-right" className="false-celling">
                <div className="false-celling-content">
                    <div className="false-celling-images">
                        <img src={image} alt="Image1" className="false-celling-image" />
                    </div>
                    <div className="false-celling-text">
                        <h1>False Ceiling </h1>
                        <p>
                        At Konnecta Technical Services, our False Ceiling Work division is dedicated to transforming your spaces with elegant and functional ceiling solutions. 
                        Our experienced team provides expert installation services tailored to meet your design preferences and practical needs.
                        </p>
                        <h5>Design and Aesthetic Enhancement</h5>
                        <p>
                            False ceilings are an excellent way to add a touch of elegance to any room. Our team offers a wide range of design options, including modern, classic, and artistic styles, to complement your existing decor and create a cohesive look.
                        </p>
                        <h5>Acoustic and Thermal Insulation</h5>
                        <p>
                        Beyond aesthetics, false ceilings provide practical benefits, such as improved acoustics and thermal insulation. Our solutions help reduce noise levels and maintain a comfortable indoor temperature, making your space more pleasant and energy-efficient.
                        </p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" className='false-celling-text2 false-celling-text'>
                <h1>Why Choose Us?</h1>
                <ul>
                    <li> <b>Innovative Designs:</b> We stay up-to-date with the latest trends and techniques in false ceiling design.</li>
                    <li> <b>Professional Installation:</b> Our skilled technicians ensure precision and quality in every installation.</li>
                    <li> <b>Customer Satisfaction:</b> We work closely with you to ensure your vision is realized and your expectations are exceeded.</li>
                </ul>
                <p>
                Elevate your home or business with our expert false ceiling services. Contact Konnecta Technical Services today to explore the possibilities and discover how we can enhance your space.
                </p>
            </div>
        </>
    )
}
