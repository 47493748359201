import React from 'react'
import HomeSlide from './Home_slide'
import HomeMiddle from './Home_middle'
import HomeMiddle2 from './Home_middle2'
import HomeServices from './HomeServices'
import HomeContact from './HomeContact'

export default function Home() {
  return (
    <div>
        <HomeSlide/>
        <HomeMiddle/>
        <HomeMiddle2/>
        <HomeServices/>
        <HomeContact/>
    </div>
  )
}
