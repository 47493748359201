import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import image from '../img/Garden2.jpeg'
import '../Css/Gradenmaintain.css'

export default function Gardenmaintain() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <>
            <div data-aos="fade-right" className="garden-maintain">
                <div className="garden-maintain-content">
                    <div className="garden-maintain-images">
                        <img src={image} alt="Image1" className="garden-maintain-image" />
                    </div>
                    <div className="garden-maintain-text">
                        <h1>Garden Landscaping</h1>
                        <p>
                        With regards to developing and saving the ideal scenes of the UAE, Konnecta technical services assumes a basic part. We give laid out finishing administrations in Dubai and assist you with accomplishing the two objectives, whether you need arranging administrations 
                        Dubai for your home or basically clean up the bygone one. Our expertise in planning and creating extraordinary nurseries for property holders is unparalleled; we give an all in one resource to the entirety of your finishing and cultivating prerequisites.
                        We guarantee you that no work is subcontracted or left in the possession of different associations since we have our own in-house development groups. We offer all that from full-administration arranging to cultivate format and invigorate.
                        </p>
                        <p>
                        Our group's imaginative thoughts and aptitude in delicate arranging arrangements transform dead spaces into rich corporate front yards. We will make a staggering magnum opus or a simple to-keep up with line with our long periods of unmatched information in the plan and establishment of premium nurseries.
                        </p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" className='garden-maintain-text2 garden-maintain-text'>
                <h1>Garden Maintenance Services Dubai</h1>
                <p>
                Do you require cultivating support Dubai administrations? Indeed, 
                Konnecta administration will be satisfied to help. Konnecta specialized help is a full-administration scene plan and development firm. 
                We offer exceptional, hand crafts and establishments that are fit to every one of our clients' particular prerequisites. Konnecta spends significant time in developing tastefully satisfying yards, restoring gardens, and reconsidering your external space.
                </p>
            </div>
        </>
    )
}
