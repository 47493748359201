import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import image from '../img/plumbing2.jpeg'
import '../Css/Plumbing.css'

export default function Plumbing() {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);
    return (
        <>
            <div data-aos="fade-right" className="plumbing">
                <div className="plumbing-content">
                    <div className="plumbing-images">
                        <img src={image} alt="Image1" className="plumbing-image" />
                    </div>
                    <div className="plumbing-text">
                        <h1>Plumbing & Sanitary</h1>
                        <p>
                        Konnecta Technical Services offers reliable and efficient plumbing solutions to meet the needs of both residential and commercial clients.
                        Our Plumber Work division is committed to providing high-quality services that ensure the safety and functionality of your plumbing systems.
                        </p>
                        <h5>
                        Comprehensive Plumbing Solutions
                        </h5>
                        <p>
                        Our team of experienced plumbers is equipped to handle a wide range of plumbing issues, from routine maintenance to emergency repairs. We address leaks, clogs, and other plumbing problems quickly and efficiently to minimize disruption to your daily routine
                        </p>
                        <h5>Fixture Installation and Upgrades</h5>
                        <p>
                        Whether you’re renovating your kitchen or bathroom or simply updating your fixtures, our plumbers provide expert installation services for sinks, faucets, toilets, and more. We ensure that every installation is done to the highest standards of quality and performance
                        </p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" className='plumbing-text2 plumbing-text'>
                <h1>Why Choose Us?</h1>
                <ul>
                    <li> <b>Expert Technicians: </b>Our plumbers are highly trained and experienced professionals.</li>
                    <li> <b>Reliable Service: </b> We prioritize punctuality and reliability to ensure your plumbing needs are met promptly.</li>
                    <li> <b>Customer Care: </b>  We are dedicated to providing exceptional customer service and ensuring your satisfaction with every job..</li>
                </ul>
                <p>
                For all your plumbing needs, trust the experts at Konnecta Technical Services. Contact us today to schedule an appointment and experience the difference of working with a dedicated and skilled team.
                </p>
            </div>
        </>
    )
}
