const data = [
  {
    title: "Electrical and Air Conditiong",
    content: `
    ac air cool cold wire switch electric breaker energy Middle Easterner Emirates cooling residue grime dregs creature dung and different pollutants
    microbes parasite buildup 
    `,
    path: "/service/ac"
  },
  {
    title: "Gallery",
    content: `
    pic picture gallery images img people worker saleman photography
    `,
    path: "/gallery"
  },
  {
    title: "Tile Marble",
    content: `
    tile marble stone tracks risers  marble  rock record limestone quartz york stone
    `,
    path: "/service/tileMarble"
  },
  {
    title: "Kitchen Renovation",
    content: `
    kitchen renovation  shelf oven fridge refrigerator stove cooking fire 
    `,
    path: "/service/kitchen"
  },
  {
    title: "False Ceiling Works",
    content: `
    roof false celling work works elegance to any room elegance to any room decor and create a cohesive look aesthetics, false ceilings
    indoor temperature, making your space more pleasant and energy-efficient.
    Professional Installation:
    `,
    path: "/service/falsecelling"
  },
  {
    title: "Wall Painting Service",
    content: `
    wall painting canvas inside painting loft painting office painting and wall painting colours
    `,
    path: "/service/wallpainting"
  },
  {
    title: "Garden Maintainance",
    content: `
    garden tree plant maintainance grass lawn vail green greenery o2 co2 front yards gardens yard trees plants crop vegetable fruits
    `,
    path: "/service/gardenmaintainance"
  },
  {
    title: "Plumbing & Saintary Contracting",
    content: `
    leaks leak clog clogs plumbing plumber sink sinks faucets faucet toilet toilet saintary
    `,
    path: "/service/plumbing"
  },
  {
    title: "carpentry & contracting",
    content: `
    furniture cabinetry vintage pieces carpenters carpenter wood woods table chain sofa seat door wooden floor wardrobe
    `,
    path: "/service/carpentry"
  },
  {
    title: "Swimming Pools",
    content: `
    water swim swimming pool pools cleaning cover  stepping stools, water testing units, pool synthetic substances, and, surprisingly, programmed cleaners
    `,
    path: "/service/swimmingpool"
  },

  {
    title: "About Us",
    content: `
      At Konnecta technical services we put stock in bringing and giving best to our clients at every one of our administrations.
      From garden when the best administrations are given its is and agreeable for the client, it can change your places into the best and more secure spaces, and there Konnecta technical service specialized administrations comes to give you an outline in complete arrangement giving.
      
      Konnecta technical service, put stock in quality, advancement, and accuracy which is portrayed from our work too. Our gifted experts are dedicated and passionate to deliver the best results to our clients. Your satisfaction matters most in our business, thus, we follow a collaborative and consultative approach where we value your suggestions and blend it with our innovation to bring out the best of our services.

      Some of our areas of expertise are:
      aboutus
      term and condition
      info 

    `,
    path: "/aboutus"
  },

  {
    title: "Service",
    content: `
    Services service work
    `,
    path: "/service"
  },
  {
    title: "Home",
    content: `
    home quantity expert professionalism safe and secure 
    LEADING TECHNICAL SERVICES DUBAI
    Konnecta, believe in quality, innovation, and precision which is depicted from our work as well. Our skilled professionals are committed and passionate to deliver the best results
    who we are 
    `,
    path: "/"
  },
  {
    title: "Contactus",
    content: `
    assistance help aid 
    Email phone contact mobile communication info query comment chat
    `,
    path: "/contactus"
  },


    // Add more content for other pages
  ];
  
  export default data;
  