import React from 'react';
import slide1 from '../img/slide1.jpg';
import slide2 from '../img/slide2.jpg';
import slide3 from '../img/slide3.jpg';
import { Link } from 'react-router-dom';
import '../Css/Home_slide.css';

export default function Home_slide() {

  return (
    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
      <div  className="carousel-inner ">
        <div className="carousel-item active" data-bs-interval="10000">
          <img src={slide1} className="d-block slide w-100 " alt="pics1" />
          <div className="carousel-caption custom-caption">
            <h1>Great Technology</h1>
            <h2>Best Services</h2>
            <p>Konnecta Technical Services Company is based in Dubai and our progress is always fulfilling our customer demands</p>
            <Link to='/service' className="btn btn-primary">Our Services</Link>
            <Link to='/contactus' className="btn btn-secondary">Contact Us</Link>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="2000">
          <img src={slide2} className="d-block slide w-100" alt="..." />
          <div className="carousel-caption custom-caption">
            <h1>Great Technology</h1>
            <h2>Best Services</h2>
            <p>Konnecta Technical Services Company is based in Dubai and our progress is always fulfilling our customer demands</p>
            <Link to='/service' className="btn btn-primary">Our Services</Link>
            <Link to='/contactus' className="btn btn-secondary">Contact Us</Link>
          </div>
        </div>
        <div className="carousel-item">
          <img src={slide3} className="d-block slide w-100" alt="..." />
          <div className="carousel-caption custom-caption">
            <h1>Great Technology</h1>
            <h2>Best Services</h2>
            <p>Konnecta Technical Services Company is based in Dubai and our progress is always fulfilling our customer demands</p>
            <Link to='/service' className="btn btn-primary">Our Services</Link>
            <Link to='/contactus' className="btn btn-secondary">Contact Us</Link>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev custom-carousel-control" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
        <span className="carousel-control-prev-icon custom-carousel-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next custom-carousel-control" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
        <span className="carousel-control-next-icon custom-carousel-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
